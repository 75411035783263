/* Book */

.book {
  margin-bottom: 25px;
  position: relative;
  width: 400px;
  height: 530px;
  transition: transform 0.5s;

  .paper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 1500px;

    .front,
    .back {
      background-color: #f0f3bd;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: left;
      transition: transform 0.5s;
      border-radius: 13px;

      &.front {
        z-index: 1;
        backface-visibility: hidden;
        border-left: 2px solid #a3b18a;
      }

      &.back {
        z-index: 0;
      }

      .front-content,
      .back-content {
        width: 100%;
        height: 100%;
        align-self: center;
        display: block;
        justify-content: center;
        margin-top: 10px;
      }

      .back-content {
        transform: rotateY(180deg);
      }
    }
  }
}

/* Paper flip effect */
.flipped {
  .front,
  .back {
    transform: rotateY(-180deg);
  }
}
/* 
    /* Controller Buttons */

/* Paper stack order */
#p1 {
  z-index: 3;
}

#p2 {
  z-index: 2;
}

#p3 {
  z-index: 1;
}
