@import "./components/Style/book.scss";
@import "./components/Style/_slideShow.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Georgia, "Times New Roman", Times, serif;
}

body {
  background-color: #132a13;
}
