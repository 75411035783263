.slideshow-container {
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .left-section {
    margin-top: 12px;
    margin-bottom: 12px;
    flex: 3;
    .image-gallery-content {
      border-radius: 2px;
      overflow: hidden;
      border: 5px solid #132a13;
      border-radius: 25px;
    }

    .image-gallery-image {
      margin: 0;
      padding: 0;
    }

    .image-gallery-slide img {
      width: 100%;
      height: 450px;
      max-height: 80vh;
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
    }

    .image-gallery-slide-wrapper {
      background-color: #f2e9e4;
    }

    .image-gallery-slide-wrapper {
      background-color: transparent;
    }

    .fullscreen .image-gallery-slide img {
      height: 100vh;
      max-height: 100vh;
    }

    .image-gallery-slide {
      border: 1px solid rgb(0, 0, 0);
      align-items: center;
      bottom: 70px;
      color: #fff;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    .image-gallery-description {
      color: #fff;
      font-size: xx-large;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 0;
    }

    .image-gallery-thumbnail {
      border-radius: 5px;
      background-color: rgb(0, 0, 0);
    }

    .image-gallery-thumbnail-image {
      border-radius: 5px;
    }

    .image-gallery-thumbnails-wrapper {
      background-color: #132a13;
    }

    .image-gallery-right-nav .image-gallery-svg {
      height: 60px;
      width: 60px;
      border: 1px solid grey;
      border-radius: 25px;
      background-color: #132a13;
    }

    .image-gallery-left-nav .image-gallery-svg {
      height: 60px;
      width: 60px;
      border: 1px solid grey;
      border-radius: 25px;
      background-color: #132a13;
    }
  }
}

@media (max-width: 600px) {
  .slideshow-container {
    width: 100%;
    flex-direction: column;
    gap: 5px;

    .left-section {
      margin-top: 8px;
      margin-bottom: 8px;
      flex: 1;

      .image-gallery-content {
        border: 2px solid #132a13;
        border-radius: 15px;
      }

      .image-gallery-slide img {
        height: auto;
        max-height: 50vh;
      }

      .fullscreen .image-gallery-slide img {
        height: 100vh;
        max-height: 100vh;
      }

      .image-gallery-slide,
      .image-gallery-description {
        bottom: 50px;
      }

      .image-gallery-right-nav .image-gallery-svg,
      .image-gallery-left-nav .image-gallery-svg {
        height: 40px;
        width: 40px;
        border-radius: 15px;
      }
    }
  }
}
